import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tab, Tabs } from '@mui/material';
import { CustomButton } from 'components/shared';
import Loading from 'components/UI/Loading';
import Title from 'components/UI/Title';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';

import { IEmailNotificationsSendingData, IEmailNotificationsSettings, IEmailNotificationTypes } from '@types';

import FormCheckboxWithTitle from '../../../formComponents/FormCheckboxWithTitle';
import { dispatch, useAppSelector } from '../../../redux/hooks';
import { settingsMiddleware, settingsSelector } from '../../../redux/slices/settings';

enum EmailNotificationsTabs {
  manual = 'MANUAL_REVIEW',
  funding = 'FUNDING_REQUEST',
}

const EmailNotificationsModal = () => {
  const [tabValue, setTabValue] = useState<EmailNotificationsTabs>(EmailNotificationsTabs.manual);

  const notificationTypes = useAppSelector(settingsSelector.notificationTypes);
  const duplicatedNotificationTypes = useAppSelector(settingsSelector.duplicatedNotificationTypes);
  const notificationTypesLoading = useAppSelector(settingsSelector.notificationTypesLoading);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.EmailNotificationsModal));
  };

  const methods = useForm<IEmailNotificationTypes>({
    defaultValues: {
      manualReview: [],
      fundingRequest: [],
    },
    mode: 'onSubmit',
  });

  const { handleSubmit, setValue, watch } = methods;

  useEffect(() => {
    if (notificationTypes) {
      setValue('manualReview', notificationTypes?.manualReview);
      setValue('fundingRequest', notificationTypes?.fundingRequest);
    }
  }, [notificationTypes, setValue]);

  const onSubmit = (data: IEmailNotificationTypes) => {
    const sendingData: IEmailNotificationsSendingData[] = [];

    [...data.manualReview, ...data.fundingRequest].forEach((item) => {
      if (duplicatedNotificationTypes && duplicatedNotificationTypes[item.id]) {
        sendingData.push({
          notificationInfoId: duplicatedNotificationTypes[item.id].id,
          isEnabledForEmail: item.isEnabled,
        });
      }

      sendingData.push({
        notificationInfoId: item.id,
        isEnabledForEmail: item.isEnabled,
      });
    });

    dispatch(settingsMiddleware.updateEmailNotifications(sendingData));
  };

  useEffect(() => {
    dispatch(settingsMiddleware.getNotificationTypes());
  }, []);

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          paddingTop: 0,
        },
      }}
    >
      <Grid sx={{ width: '600px' }}>
        <DialogTitle
          sx={{
            backgroundColor: 'rgba(249, 248, 254, 1)',
          }}
        >
          <Title
            title="Email Notifications"
            sx={{
              margin: 0,
              padding: 0,
              display: 'block',
              textAlign: 'center',
              width: '100%',
              fontSize: '18px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          {notificationTypesLoading ? (
            <Loading />
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Tabs
                    value={tabValue}
                    onChange={(event, value) => {
                      setTabValue(value);
                    }}
                    sx={{
                      '& .css-13xc10z-MuiButtonBase-root-MuiTab-root.Mui-selected': {
                        color: '#7B61FF',
                      },
                    }}
                  >
                    <Tab value={EmailNotificationsTabs.manual} label="Manual Review" />
                    <Tab value={EmailNotificationsTabs.funding} label="Funding Request" />
                  </Tabs>
                </Box>
                <Box>
                  {tabValue === EmailNotificationsTabs.manual && watch('manualReview').length && (
                    <Box>
                      <Box>
                        {notificationTypes?.manualReview?.map((el, index) => (
                          <FormCheckboxWithTitle
                            name={`manualReview.${index}.isEnabled`}
                            title={el.title}
                            description={el.description}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                  {tabValue === EmailNotificationsTabs.funding && watch('fundingRequest').length && (
                    <Box>
                      {notificationTypes?.fundingRequest?.map((el: IEmailNotificationsSettings, index: number) => (
                        <FormCheckboxWithTitle
                          name={`fundingRequest.${index}.isEnabled`}
                          title={el.title}
                          description={el.description}
                        />
                      ))}
                    </Box>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                    {/* @todo Anoush custom button propTypes is not set properly, fix types */}
                    <CustomButton onClick={onClose} variant="outlined">
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit">Save</CustomButton>
                  </Box>
                </Box>
              </form>
            </FormProvider>
          )}
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default EmailNotificationsModal;

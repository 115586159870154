import { dateTime } from 'helpers';
import { responseErrorHandler } from 'helpers/ErrorHandler';
import { FileManager } from 'helpers/FileManager';

import { CreateApplicationJourneyData } from '../../../helpers/CreateApplicationJourneyData';
import { APPLICATIONS_KEY, PersistManager } from '../../../helpers/PersistManager';
import {
  API_APPLICATION_CANCEL,
  API_APPLICATION_JOURNEY_RESEND_EMAIL,
  API_DOCUMENT_DOWNLOAD,
  API_DOCUMENT_LIST,
  API_DOCUMENT_TYPES,
  API_DOCUMENT_UPLOAD,
  API_FUNDING_UPLOAD,
  API_GET_LEAD_FUNDS,
  API_GET_LEAD_LOGS,
  API_LEAD_FUNDS_STATUS_CHANGE,
  API_LEADS,
  API_LEADS_ADD_NOTE,
  API_LEADS_BY_ID,
  // LEADS_CREDIT_REPORT, need to be removed deprecated
  API_LEADS_JOURNEY,
  API_LEADS_NOTE,
  API_LEADS_NOTES,
  API_LEADS_RERUN,
  API_LEADS_RERUN_STEPS,
  API_LEADS_STEP_STATUSES,
  API_MANUAL_REVIEW_UPLOAD,
  API_MANUAL_REVIEWS_REVERT,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import {
  IDocumentsMenu,
  IDocumentsUploadLoading,
  IFunds,
  ILeadJourney,
  ILeadJourneyGroup,
  ILeadJourneyRow,
  ILeadJourneyStep,
  ILeadsDetails,
  ILeadsReqBody,
} from '../../../types';
import { SeveritiesType } from '../../../types/snackbar';
import { dispatch, getStateBySelector } from '../../hooks';
import { manualReviewMiddleware } from '../manualReviews';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import { LeadsDataChanger, LeadsDetailsDataChanger } from './helpers';
import { leadsMiddleware } from './index';
import slice from './slice';

import classes from '../../../assets/scss/journey.module.scss';

const {
  setLeadsListLoading,
  getLeadsList,
  setLeadsListCount,
  setLeadsFilters,
  hasError,
  setLeadsDetails,
  setRerunSteps,
  setRerunLoading,
  setLeadsDetailsLoading,
  setLeadsJourneyStatus,
  setLeadsJourneyLoading,
  setLeadsJourney,
  setLogs,
  setLogsLoading,
  setFunds,
  setFundsLoading,
  setApplicationJourney,
  setApplicationJourneyLoading,
  // setFile, deprecated remove with credit report
  setFetchFileLoading,
  setDocumentsMenu,
  setDocumentsMenuLoading,
  setDocumentsList,
  setDocumentsListLoading,
  setDocumentsUploadTypes,
  setDocumentsUploadLoading,
  setLeadDetailsInitialTab,
  setFilePreviewLoading,
  setFileDownloadLoading,
  setRevertLoading,
  setResendEmailLoading,
  setResendEmail,
  setNotesAddOrEditLoading,
  setNotesList,
  setNotesListLoading,
  setNotesUsers,
  setGeneralInformationData,
  setCancelApplicationLoading,
} = slice.actions;

export const fetchLeadsList = (data?: ILeadsReqBody) => async () => {
  try {
    if (!data) {
      data = PersistManager.getData(APPLICATIONS_KEY);
    }

    if (!data) {
      dispatch(getLeadsList(null));
    } else {
      dispatch(setLeadsListLoading(true));

      const oldFilters = getStateBySelector('leads').leadsFilters;

      const filters = { ...oldFilters, ...data };

      const { data: response } = await DataService.post(API_LEADS(), filters);

      dispatch(setLeadsListCount(response?.itemsCount));
      dispatch(setLeadsFilters(filters));

      PersistManager.setData(APPLICATIONS_KEY, filters);

      dispatch(getLeadsList(LeadsDataChanger(response)));
    }
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    setTimeout(() => {
      dispatch(setLeadsListLoading(false));
    }, 100);
  }
};

export const resetLeadsFilters = () => () => {
  setLeadsFilters(null);
};

export const fetchLeadsDetails = (id: string) => async () => {
  try {
    dispatch(setLeadsDetailsLoading(true));

    const { data: response } = await DataService.getJson(API_LEADS_BY_ID(id));

    dispatch(setGeneralInformationData(LeadsDetailsDataChanger(response)));

    dispatch(setLeadsDetails(response));
    dispatch(setLeadsDetailsLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setLeadsDetailsLoading(false));
  }
};

const clearLeadsDetails = () => () => dispatch(setLeadsDetails(null));

const createJourneyRows = (
  journey: ILeadJourney[],
  rows: ILeadJourneyRow[] = [],
  isSuccess: boolean = true,
  children: boolean = false,
) => {
  let lastActionType = 0;

  journey.forEach((item: ILeadJourney) => {
    const step: ILeadJourneyStep = {
      id: Math.random(),
      title: item.name,
      desc: item.decisionTreeName,
      value: item.templateFieldValue,
      message: item.message,
      aanMessage: item.aanMessage,
      className: !item.isSuccess ? classes.red : '',
    };

    if (children && rows.length) {
      const row: ILeadJourneyRow = rows[rows.length - 1];
      const group: ILeadJourneyGroup = row.groups[row.groups.length - 1];

      group.steps.push(step);
    } else {
      const group: ILeadJourneyGroup = {
        id: Math.random(),
        steps: [step],
        actionType: item.actionType,
        className: '',
      };

      if (item.actionType === lastActionType) {
        rows[rows.length - 1].groups.push(group);
      } else {
        rows.push({
          id: Math.random(),
          title: item.actionTypeName,
          groups: [group],
        });
      }

      lastActionType = item.actionType;
    }

    if (isSuccess) {
      isSuccess = item.isSuccess;
    }

    if (item.childrenAction.length) {
      const { isSuccess: isSuccessChild } = createJourneyRows(item.childrenAction, rows, isSuccess, true);

      if (!isSuccessChild) {
        isSuccess = isSuccessChild;
      }
    }
  });

  return { rows, isSuccess };
};

export const fetchLeadsJourney = (id: string) => async () => {
  try {
    dispatch(setLeadsJourneyLoading(true));

    const { data: response } = await DataService.getData(API_LEADS_JOURNEY(id));

    const { rows, isSuccess } = createJourneyRows(response);

    dispatch(setLeadsJourneyStatus(isSuccess));
    dispatch(setLeadsJourney(rows));
    dispatch(setLeadsJourneyLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setLeadsJourney([]));
    dispatch(setLeadsJourneyLoading(false));
  }
};

export const fetchLeadsApplicationJourney = (id: string) => async () => {
  try {
    dispatch(setApplicationJourneyLoading(true));

    const { data: response } = await DataService.getData(API_LEADS_STEP_STATUSES(id));

    const changedResponse = CreateApplicationJourneyData(response);

    dispatch(setApplicationJourney(changedResponse));

    dispatch(setApplicationJourneyLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setApplicationJourneyLoading(false));
  }
};

const fetchLogs = (id: string) => async () => {
  try {
    dispatch(setLogsLoading(true));

    const { data: response } = await DataService.getData(API_GET_LEAD_LOGS(id));

    dispatch(setLogs(response));
    dispatch(setLogsLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setLogsLoading(false));
  }
};

const fetchFunds =
  (id: string, currentPage = 1, pageSize = 10) =>
  async () => {
    try {
      dispatch(setFundsLoading(true));

      const { data: response } = await DataService.getData(API_GET_LEAD_FUNDS(id), {
        currentPage,
        pageSize,
      });

      response?.forEach((item: IFunds) => {
        item.requestedDate = dateTime(item.requestedDate);
      });

      dispatch(setFunds(response));
      dispatch(setFundsLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(setFundsLoading(false));
    }
  };

const updateLeadsFundsList = (data: IFunds[]) => async () => {
  dispatch(setFunds(data));
};

const fetchChangeFundStatus =
  (id: string, fundId: string, status: number, setMode: (value: string) => void, reason?: string) => async () => {
    try {
      dispatch(setFundsLoading(true));

      await DataService.put(API_LEAD_FUNDS_STATUS_CHANGE(), {
        status,
        leadId: id,
        fundId,
        rejectReason: reason ?? '',
      });
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.success,
            description: status === 1 ? 'Approved Successfully' : 'Rejected Successfully',
          },
        }),
      );
      dispatch(fetchFunds(id));
      setMode('table');
      dispatch(setFundsLoading(false));
    } catch (error) {
      dispatch(setFundsLoading(false));
      dispatch(hasError(error));
    }
  };

const fetchFile = async (id: string) => {
  try {
    dispatch(setFetchFileLoading(true));

    const response = await DataService.getFile(API_DOCUMENT_DOWNLOAD(id));

    dispatch(hasError(null));
    dispatch(setFetchFileLoading(false));

    return response?.data;
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setFetchFileLoading(false));

    return null;
  }
};

const downloadFile = (id: string, name: string) => async () => {
  const fileDownloadLoading = { ...getStateBySelector('leads').fileDownloadLoading };

  fileDownloadLoading[id] = id;
  dispatch(setFileDownloadLoading({ ...fileDownloadLoading }));

  const response = await fetchFile(id);

  FileManager.download(response as File, name);
  delete fileDownloadLoading[id];
  dispatch(setFileDownloadLoading({ ...fileDownloadLoading }));
};

const previewFile = (id: string, name: string, callback?: (url: string) => void) => async () => {
  const { filePreviewLoading: preview } = getStateBySelector('leads');
  const filePreviewLoading = { ...preview };

  filePreviewLoading[id] = id;

  dispatch(setFilePreviewLoading({ ...filePreviewLoading }));

  const response = await fetchFile(id);

  FileManager.preview(response as File, name, callback);
  delete filePreviewLoading[id];
  dispatch(setFilePreviewLoading({ ...filePreviewLoading }));
};

const changeLeadDetailsInitialTab = (tabValue: number | string) => async () => {
  dispatch(setLeadDetailsInitialTab(tabValue));
};

const updateLeadsList = (listData: ILeadsDetails[]) => async () => {
  dispatch(getLeadsList(listData));
};

const updateLeadDetails = (listDetails: ILeadsDetails) => async () => {
  dispatch(setLeadsDetails(listDetails));
};

const fetchDocumentsMenuItems = () => async () => {
  try {
    const savedTypes = getStateBySelector('leads').documentsUploadTypes;

    if (savedTypes.length) {
      dispatch(setDocumentsUploadTypes(savedTypes.filter((el: IDocumentsMenu) => !el.autoGenerated)));

      return;
    }

    dispatch(setDocumentsMenuLoading(true));

    const { data: response } = await DataService.getData(API_DOCUMENT_TYPES());

    const uploadTypes = response.filter((el: IDocumentsMenu) => !el.autoGenerated);

    dispatch(setDocumentsUploadTypes(uploadTypes));
    dispatch(setDocumentsMenu(response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setDocumentsMenuLoading(false));
  }
};

const fetchDocumentsList = (leadId: string) => async () => {
  try {
    dispatch(setDocumentsListLoading(true));

    const { data: response } = await DataService.getData(API_DOCUMENT_LIST(leadId));

    response?.forEach((item: { date: string }) => {
      item.date = dateTime(item.date);
    });

    dispatch(setDocumentsList(response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    setTimeout(() => {
      dispatch(setDocumentsListLoading(false));
    }, 100);
  }
};

const changeUploadingFileState = (fileIndex: number, fileList: IDocumentsUploadLoading[], fileState: number) => {
  const loadingList = fileList.map((el: IDocumentsUploadLoading) => {
    if (el.index === fileIndex) {
      return {
        index: el.index,
        fileState,
      };
    }

    if (el.index < fileIndex) {
      return {
        index: el.index,
        fileState: 2,
      };
    }

    return el;
  });

  dispatch(setDocumentsUploadLoading(loadingList));
};

const fetchUploadFilesByOne =
  (
    file: File,
    fileIndex: number,
    fileList: IDocumentsUploadLoading[],
    leadId: string,
    documentCategory: string,
    mode: string,
    reasonId?: string,
    documentType?: string,
  ) =>
  async () => {
    try {
      changeUploadingFileState(fileIndex, fileList, 1);

      if (file) {
        if (mode === 'Manual') {
          await DataService.postFile(API_MANUAL_REVIEW_UPLOAD(`${reasonId}`), {
            LeadId: leadId,
            DocumentCategoryId: documentCategory,
            DocumentTypeId: documentType,
            File: file,
          });
        } else if (mode === 'Fundings') {
          await DataService.postFile(API_FUNDING_UPLOAD(`${reasonId}`), {
            LeadId: leadId,
            DocumentCategoryId: documentCategory,
            DocumentTypeId: documentType,
            File: file,
          });
        } else {
          await DataService.postFile(API_DOCUMENT_UPLOAD(), {
            LeadId: leadId,
            DocumentCategoryId: documentCategory,
            DocumentTypeId: documentType,
            File: file,
            Id: null,
          });
        }
      }

      changeUploadingFileState(fileIndex, fileList, 2);

      return true;
    } catch (error: any) {
      changeUploadingFileState(fileIndex, fileList, 3);
      responseErrorHandler(error);

      return false;
    }
  };

const fetchUploadFiles =
  (data: File[], leadId: string, documentCategory: string, mode: string, reasonId?: string, documentType?: string) =>
  async () => {
    try {
      const fileList = data.map((el: File, index: number) => ({
        index,
        fileState: 0,
      }));

      dispatch(setDocumentsUploadLoading(fileList));

      let closeModal = true;

      for (let i = 0; i <= data.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const response = await dispatch(
          fetchUploadFilesByOne(data[i], i, fileList, leadId, documentCategory, mode, reasonId, documentType),
        );

        if (!response) {
          closeModal = false;
          break;
        }
      }

      if (closeModal) {
        if (mode === 'Manual') {
          dispatch(viewsMiddleware.closeModal(ModalName.ReasonUploadFilesModal));
        } else {
          dispatch(viewsMiddleware.closeModal(ModalName.DocumentsUploadFilesModal));
        }
      }

      if (mode === 'Manual') {
        dispatch(manualReviewMiddleware.fetchManualReviewsReasonByLeadId(`${reasonId}`));
      } else if (mode === 'Fundings') {
        dispatch(fetchFunds(leadId));
      } else {
        dispatch(fetchDocumentsList(leadId));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };

const fetchClearFileList = () => async () => {
  dispatch(setDocumentsUploadLoading(null));
};

const fetchRerunSteps = (applicationId: string) => async () => {
  try {
    dispatch(setRerunSteps([]));
    dispatch(setRerunLoading(true));

    const response = await DataService.put(API_LEADS_RERUN_STEPS(applicationId));

    dispatch(setRerunSteps(response));
    dispatch(setRerunLoading(false));
  } catch (error) {
    dispatch(setRerunLoading(false));
    dispatch(hasError(error));
  }
};

const rerunStep = (applicationId: string, stepName: string) => async () => {
  try {
    dispatch(setRerunLoading(true));

    const { result } = await DataService.post(API_LEADS_RERUN(applicationId, stepName), {});

    if (result) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: `${result}`,
          },
        }),
      );

      return;
    }

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: `The application ${applicationId} has been re-initiated.`,
        },
      }),
    );
    dispatch(
      viewsMiddleware.setRedirectionState({
        path: '/applications/',
        params: '',
        apply: true,
      }),
    );
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setRerunLoading(false));
    dispatch(viewsMiddleware.closeModal(ModalName.RerunModal));
  }
};

const revertApplication = (leadId: string, applicationId: string, note: string) => async () => {
  try {
    dispatch(setRevertLoading(true));

    await DataService.post(API_MANUAL_REVIEWS_REVERT(), {
      leadId,
      applicationId,
      note,
    });

    const { leadsFiltersId } = getStateBySelector('leads');

    dispatch(fetchLeadsList(leadsFiltersId));

    dispatch(viewsMiddleware.closeModal(ModalName.RevertApplicationModal));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setRevertLoading(false));
  }
};

const getResendDocumentEmail = (leadId: string) => async () => {
  try {
    dispatch(setResendEmailLoading(true));

    const { data: response } = await DataService.getData(API_APPLICATION_JOURNEY_RESEND_EMAIL(leadId));

    dispatch(setResendEmail(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setResendEmailLoading(false));
  }
};

const updateResendDocumentEmail = (leadId: string, emailAddress: string) => async () => {
  try {
    dispatch(setResendEmailLoading(true));

    const response = await DataService.put(API_APPLICATION_JOURNEY_RESEND_EMAIL(leadId), { emailAddress });

    if (response) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.success,
            description: 'Document Successfully Resented to email',
          },
        }),
      );
    } else {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: 'Something went wrong.',
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(viewsMiddleware.closeModal(ModalName.ResendDocumentModal));
    dispatch(setResendEmailLoading(false));
  }
};

const createNote =
  (leadId: string, note: string, userId?: number, rowsPerPage = 10) =>
  async () => {
    try {
      dispatch(setNotesAddOrEditLoading(true));

      await DataService.post(API_LEADS_ADD_NOTE(), {
        leadId,
        note,
      });

      dispatch(leadsMiddleware.getNotes(leadId, 1, rowsPerPage, userId && userId !== -1 ? `${userId}` : null));

      dispatch(viewsMiddleware.closeModal(ModalName.AddNoteModal));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setNotesAddOrEditLoading(false));
    }
  };

const getNotes = (leadId: string, pageNumber: number, pageSize: number, userId?: string | null) => async () => {
  try {
    dispatch(setNotesListLoading(true));

    const { data: response } = await DataService.getData(
      API_LEADS_NOTES(pageNumber, pageSize, leadId, userId && userId !== '-1' ? userId : null),
    );

    const usersList = Object.keys(response.usernames).map((el) => ({ id: el, name: response.usernames[el] }));

    usersList.unshift({ id: '-1', name: 'All' });

    dispatch(setNotesUsers(usersList));
    dispatch(setNotesList(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setNotesListLoading(false));
  }
};

const updateNote =
  (noteId: string, note: string, leadId: string, page = 1, rowsPerPage = 10, userId?: number) =>
  async () => {
    try {
      dispatch(setNotesAddOrEditLoading(true));

      await DataService.post(API_LEADS_NOTE(), {
        noteId,
        note,
      });

      dispatch(leadsMiddleware.getNotes(leadId, page, rowsPerPage, userId && userId !== -1 ? `${userId}` : null));

      dispatch(viewsMiddleware.closeModal(ModalName.AddNoteModal));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setNotesAddOrEditLoading(false));
    }
  };

const deleteNote =
  (noteId: string, leadId: string, page = 1, rowsPerPage = 10, userId?: number) =>
  async () => {
    try {
      dispatch(setNotesAddOrEditLoading(true));

      await DataService.delete(API_LEADS_NOTE(), { noteId });

      dispatch(leadsMiddleware.getNotes(leadId, page, rowsPerPage, userId && userId !== -1 ? `${userId}` : null));

      dispatch(viewsMiddleware.closeModal(ModalName.DeleteNoteModal));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setNotesAddOrEditLoading(false));
    }
  };

const cancelApplication = (leadId: string, note: string, applicationId: string) => async () => {
  try {
    dispatch(setCancelApplicationLoading(true));

    await DataService.put(API_APPLICATION_CANCEL(), {
      leadId,
      note,
      applicationId,
    });

    dispatch(fetchLeadsDetails(leadId));

    dispatch(viewsMiddleware.closeModal(ModalName.CancelApplicationModal));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
  } finally {
    dispatch(setCancelApplicationLoading(false));
  }
};

export default {
  fetchLeadsList,
  fetchLeadsDetails,
  fetchLeadsJourney,
  fetchLogs,
  fetchFunds,
  fetchRerunSteps,
  rerunStep,
  fetchChangeFundStatus,
  fetchLeadsApplicationJourney,
  previewFile,
  downloadFile,
  clearLeadsDetails,
  changeLeadDetailsInitialTab,
  fetchDocumentsMenuItems,
  fetchDocumentsList,
  fetchUploadFiles,
  fetchClearFileList,
  updateLeadsList,
  updateLeadDetails,
  revertApplication,
  updateLeadsFundsList,
  resetLeadsFilters,
  getResendDocumentEmail,
  updateResendDocumentEmail,
  createNote,
  getNotes,
  updateNote,
  deleteNote,
  cancelApplication,
};

import { useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CustomButton } from 'components/shared';
import Title from 'components/UI/Title';
import { validationTreeMiddleware, validationTreeSelector } from 'redux/slices/validationTree';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import FileAttachment, { IFile } from '../../FileUploadModal/FileAttachment';

export interface IAffiliateChannelTreeUploadJsonModal {
  id: string;
}

const generateValidationMessage = (rejectedFiles: FileRejection[]) => {
  if (rejectedFiles.length === 1) {
    return rejectedFiles[0].errors[0]?.code === 'file-too-large'
      ? `${rejectedFiles[0].file.name} - Upload fail: The file is too large. Please upload file less than 20MB`
      : `${rejectedFiles[0].file.name} - Upload fail: Invalid Format. Only JSON is allowed`;
  }

  if (rejectedFiles.length > 1) {
    return 'Only one file can be uploaded';
  }

  return '';
};

const AffiliateChannelTreeUploadJsonModal = ({ id }: IAffiliateChannelTreeUploadJsonModal) => {
  const [files, setFiles] = useState<File[]>([]);
  const uploadJsonIsLoading = useAppSelector(validationTreeSelector.uploadJsonIsLoading);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxSize: 20971520,
    accept: {
      'application/json': ['.json'],
    },
    maxFiles: 1,
    multiple: false,
  });

  const removeFile = (deletedIndex: number) => {
    const arr = files.filter((el, index) => index !== deletedIndex);

    setFiles([...arr]);
  };

  const uploadFiles = () => {
    dispatch(validationTreeMiddleware.uploadTreeWithJson(id, files[0]));
  };

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.AffiliateChannelTreeUploadJsonModal));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (acceptedFiles.length) {
      setFiles([...acceptedFiles]);
    }
  }, [acceptedFiles]);

  return (
    <Dialog open onClose={onClose}>
      <Box sx={{ width: '500px' }}>
        <DialogTitle sx={{ backgroundColor: '#F9F8FE', padding: '14px', marginTop: '-12px' }}>
          <Title
            component="span"
            title="Upload JSON"
            sx={{
              margin: 0,
              padding: 0,
              display: 'block',
              textAlign: 'center',
              width: '100%',
              fontSize: '16px',
              fontWeight: 700,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ py: 3 }}>
            {files?.length ? (
              <Box
                sx={{
                  p: '19px 20px 4px',
                  minHeight: '115px',
                  overflow: 'auto',
                  borderRadius: '12px',
                  border: '1px solid #E2E8F0',
                }}
              >
                {files.map((file, index) => (
                  <FileAttachment key={file.name} file={file as IFile} index={index} removeFile={removeFile} />
                ))}
              </Box>
            ) : (
              <>
                <Box
                  {...getRootProps()}
                  sx={{
                    width: '100%',
                    height: '115px',
                    border: '2px dashed #E2E6EA',
                    borderRadius: '24px',
                    backgroundColor: '#F4F7FE',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography sx={{ marginBottom: '10px' }}>No files have been uploaded yet</Typography>
                  <Typography sx={{ marginBottom: '10px' }}>Click to browse or drag and drop your JSON file</Typography>
                </Box>
                {fileRejections.length ? (
                  <Typography
                    component="span"
                    sx={{ marginTop: '10px', fontWeight: 600, color: '#FF497F', fontSize: '12px' }}
                  >
                    {generateValidationMessage(fileRejections)}
                  </Typography>
                ) : null}
              </>
            )}
          </Box>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton onClick={uploadFiles} loading={uploadJsonIsLoading} variant="contained">
              Submit
            </CustomButton>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AffiliateChannelTreeUploadJsonModal;

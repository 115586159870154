import React, { FunctionComponent, ReactElement } from 'react';
import { ChipProps, SvgIconTypeMap, TableCellProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Theme } from '@mui/material/styles';
import { TablerIcon } from '@tabler/icons';
import { AxiosError } from 'axios';
import { Permissions } from 'components/Permissions/constants';
import { ILeadsDashboard } from 'redux/slices/dashboard/middleware';

import { IValidationTreeModel } from '../components/dataModels/validationTree';
import { ISelectOption } from '../components/UI/CustomAutocomplete';
import { IApplicationJourney } from '../helpers/CreateApplicationJourneyData';
import { ChannelTabs } from '../pages/Affiliates/Details/Channels';
import { NotificationType } from '../redux/slices/settings/helper';
import { ITag } from '../redux/slices/tags/initialState';

import { ICampaignDetails, ICampaignField, IHistory, IHistoryResponse } from './campaigns';
import { ISnackbarProps } from './snackbar';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

export type ArrangementOrder = 'asc' | 'desc' | undefined;

export type GetComparator = (o: ArrangementOrder, o1: string) => (a: KeyedObject, b: KeyedObject) => number;

export interface TabsProps {
  children?: React.ReactElement | React.ReactNode | string;
  value: string | number;
  index: number;
}

export type IDateRange = Date | string | undefined;

export interface IModifiedTableData {
  cost: number | string;
  externalServiceId: number;
  latency: string;
  requestCount: number;
  serviceName: string;
}

export interface GenericCardProps {
  title?: string;
  primary?: string | number | undefined;
  secondary?: string;
  content?: string;
  image?: string;
  dateTime?: string;
  iconPrimary?: OverrideIcon;
  color?: string;
  size?: string;
}

export type OverrideIcon =
  | (OverridableComponent<SvgIconTypeMap> & {
      muiName: string;
    })
  | React.ComponentClass<any>
  | FunctionComponent<any>
  | TablerIcon;

export interface EnhancedTableHeadProps extends TableCellProps {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: ArrangementOrder;
  orderBy?: string;
  numSelected: number;
  rowCount: number;
  onRequestSort: (e: React.SyntheticEvent, p: string) => void;
  headCells: HeadCell[];
}

export interface HeadCell {
  id: string;
  numeric: boolean;
  label: string;
  disablePadding?: string | boolean | undefined;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

export interface NavItemTypeObject {
  children?: NavItemType[];
  items?: NavItemType[];
  type?: string;
}

export interface NavItemType {
  id: string;
  icon?: GenericCardProps['iconPrimary'];
  target?: boolean;
  external?: string;
  url?: string | undefined;
  type?: string;
  title?: React.ReactNode | string;
  parent?: string;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  caption?: React.ReactNode | string;
  breadcrumbs?: boolean;
  disabled?: boolean;
  chip?: ChipProps;
}

export interface AuthSliderProps {
  title: string;
  description: string;
}

export interface ILeadsDetails {
  address: string;
  affiliateChannelId: number;
  affiliateChannelName: string;
  affiliateId: number;
  affiliateName: string;
  applicationId: string;
  campaignId: number;
  campaignName: string;
  city: string;
  createdUtc: string;
  email: string;
  phoneNumber: string;
  firstname: string;
  id: string;
  ip: string;
  lastname: string;
  processingTime: number;
  ssn: null;
  state: string;
  status: number;
  zip: string;
  vantageScore: number;
  dti: number;
  pti: number;
  tags: ITag[];
  merchantName: string;
  cltv: number;
  requiredIncome: number;
  requiredPropertyValue: number;
  country: string;
  unit: string;
  statedIncome: number | null;
  piiProperties: string[];
  borrower: string;
  isCancelable: boolean;
}

export interface ICampaignHistoryFields {
  name: string;
  oldValue: string | null;
  newValue: string | null;
}

export interface ISourcePageFilters {
  status: number;
  pageNumber: number;
  pageSize: number;
  sortOrderKey?: string;
  sortOrderValue?: boolean;
  name?: string;
}

export interface ICampaignHistory {
  actionType: string;
  entityId: string;
  fields: ICampaignHistoryFields[];
  timeStamp: string;
  userId: number;
  userName: string;
}

export interface ISimplifiedList {
  id: string;
  name: string;
}

export interface ICampaignProps {
  campaigns: ICampaignField[] | null;
  simplifiedCampaigns: ISimplifiedList[] | null;
  campaignsCount: string;
  isCampaignHistoryLoading: boolean;
  isValidatorsLoading: boolean;
  isTimeFormatSuccessLoading: boolean;
  isCampaignsListLoading: boolean;
  isDeleteCampaignLoading: boolean;
  isUpdateCampaignStatusLoading: boolean;
  isCreateCampaignLoading: boolean;
  isUpdateCampaignLoading: boolean;
  isUpdateCampaignDetailsLoading: boolean;
  isAffiliateChannelsByCampaignIdLoading: boolean;
  isSystemFieldsByCampaignIdLoading: boolean;
  isCampaignFieldsLoading: boolean;
  isCurrentCampaignByIdLoading: boolean;
  isFieldByDataTypeLoading: boolean;
  isCreateAffiliateLoading: boolean;
  isAffiliatesLoading: boolean;
  isLoading: boolean;
  currentCampaignById: ICampaignDetails | null;
  createCampaignsWithStepper: {
    id: string;
    campaignName: string;
    newCampaignStateTemporary: { [key: string]: ICampaignField[] };
  } | null;
  validators: { id: number; name: string }[] | null;
  fieldByDataType: { data: string; fields: ICampaignField[] } | null;
  campaignFields: ICampaignField[] | null;
  timeFormats: string[] | null;
  error: Error | null;
  errorMessages: string | null | Error;
  successfully: boolean;
  history: IHistoryResponse | null;
  isCampaignCreated: boolean;
  fieldByDataTypeString: string;
  affiliateChannels: string[] | null;
  currentCampaignByIdCampaignFields: ICampaignField[] | null;
  campaignFilters: ISourcePageFilters | null;
}

export interface ICountriesProps {
  code: string;
  id: string;
  name: string;
  zipCodeLength: number;
}

export interface IListsProps {
  id: number;
  name: string;
  condition: number;
  listType: number;
}

export interface ILeadsManualReviewReasonDocument {
  id: number;
  name: string;
  size: number;
  status: number;
  notes?: string;
  documentTypeId?: string | number;
  documentCategoryId?: string;
}

export interface IExternalDocuments {
  dateTime: string;
  documents: ILeadsManualReviewReasonDocument[];
}

export interface ILeadsManualReviewReason {
  id: string;
  manualReviewId: number;
  status: number;
  name: string;
  reviewLink: string;
  notes: string;
  reason: string;
  reviewerId: string;
  reviewerName: string;
  documents: ILeadsManualReviewReasonDocument[];
  externalDocuments: IExternalDocuments[];
  requiredIncome?: number | null;
  solarPanelDetails: ISolarPanelDetails[] | null;
  aanReasons?: string[] | null;
}

export interface ISolarPanelDetails {
  id: string;
  manualReviewDetailId: string;
  key: string;
  value: string | boolean;
}

export interface IDocumentsMenu {
  id: string;
  name: string;
  autoGenerated: boolean;
  description: string | null;
  isDeleted: boolean;
  tenantId: number;
  documentTypes: IDocumentType[] | null;
}

export interface IDocumentType {
  id: string;
  name: string;
  description: string;
  documentCategoryId: string;
}

export interface IDocumentList {
  id: string;
  date: string;
  leadId: string;
  documentTypeId: string;
  documentTypeName: string;
  documentCategoryId: string;
  name: string;
  reviewerName: string;
  status: string | null;
  reviewedBy: string | null;
}

export interface IDocumentsUploadLoading {
  index: number;
  fileState: number;
}

export interface ILeadsFilters {
  dateFrom: Date | string;
  dateTo: Date | string;
  status: number;
  filterAffiliateIds: string[];
  filterAffiliateChannelIds: string[];
  filterCampaignIds: string[];
  filterLeadIds: string[];
  filterEmail: string[];
  filterTags: string[];
  filterBorrowerName: string;
  searchModel: ISearchModel;
}

export enum ApplicationTabs {
  generalInfo = 'GENERAL_INFO',
  logs = 'LOGS',
  decisionJourney = 'DECISION_JOURNEY',
  applicationJourney = 'APPLICATION_JOURNEY',
  manualReview = 'MANUAL_REVIEW',
  fundingRequests = 'FUNDING_REQUESTS',
  documents = 'DOCUMENTS',
  notes = 'NOTES',
}

export interface ILeadsState {
  error: null | { code: string }; // Error type need to be improved @todo Anoush
  isLeadsListLoading: boolean;
  isLeadsDetailsLoading: boolean;
  leadsDetails: ILeadsDetails | null;
  leads: ILead[] | null;
  leadsCount: number;
  leadsFilters: ILeadsFilters | null;
  isLeadsJourneyLoading: boolean;
  journey: ILeadJourneyRow[] | null;
  journeyStatus: boolean;
  applicationJourney: { rows: IApplicationJourney[]; isSuccess: boolean } | null;
  applicationJourneyLoading: boolean;
  logs: ILog[] | [];
  logsLoading: boolean;
  funds: IFunds[] | [];
  fundsLoading: boolean;
  rerunLoading: boolean;
  rerunSteps: string[];
  documentFile: File | undefined;
  fetchFileLoading: boolean;
  leadDetailsInitialTab: number | string;
  documentsMenu: IDocumentsMenu[];
  documentsMenuLoading: boolean;
  documentsList: IDocumentList[];
  documentsListLoading: boolean;
  documentsUploadTypes: IDocumentsMenu[];
  documentsUploadLoading: IDocumentsUploadLoading[] | null;
  filePreviewLoading: { [key: string]: string };
  fileDownloadLoading: { [key: string]: string };
  revertLoading: boolean;
  resendEmailLoading: boolean;
  resendEmail: string;
  notesAddOrEditLoading: boolean;
  notesList: INoteResponse | null;
  notesListLoading: boolean;
  notesUsers: INotesUser[] | null;
  generalInformationData: IGeneralInformationData | null;
  cancelApplicationLoading: boolean;
}

export interface IGeneralInformationData {
  [key: string]: IGeneralInformationCellData[];
}

export interface IGeneralInformationCellData {
  title: string;
  value: string;
  color?: string;
  link?: string;
}

export interface INotesUser {
  id: string;
  name: string;
}

export interface INoteResponse {
  itemsCount: number;
  pagesCount: number;
  userNotes: INote[];
}

export interface INote {
  createdAt: string;
  documents: INoteDocument[] | null;
  note: string;
  noteId: string;
  reason: string | null;
  updatedAt: string | null;
  userName: string;
  userId: string;
  isCancellationNote: boolean;
}

export interface INoteDocument {
  id: string;
  name: string;
  note: string;
  status: number;
}

export interface IFunds {
  id: string;
  leadId: string;
  requestedDate: string;
  approvedDate: string;
  rejectedDate: string;
  status: number;
  reviewedBy: string;
  requestedAmount: string;
  rejectReason?: string;
  servicingCompany?: string;
  source?: string;
  reviewerId: string;
  attributes?: {
    key: string;
    value: string;
  }[];
  fundingDocuments?: IFundsFIles[];
  documentTypeId?: string;
  documentCategoryId?: string;
}

export interface IFundsFIles {
  documentId?: string;
  fundId: string;
  id: string;
  leadId: string;
  documentTypeId: string;
  name: string;
  size: number;
}

export interface ILog {
  stepName: string;
  referenceRequestData: string;
  referenceResponseData: string;
  status: string;
  dateTime: string;
  executionTime: number;
}

export interface ICountriesState {
  error: null;
  countries: ICountriesProps[];
  states: IStatesProps[];
}

export interface IDashboardState {
  error: Error | null;
  reportsLeads: ILeadsDashboard;
  reportsNoLeads: {
    data: [];
    pagesCount: number;
    itemsCount: number;
  };
  isReportsLeadsLoading: boolean;
  isReportsNoLeadsLoading: boolean;
  reportsExternalServices: {
    data: IModifiedTableData[];
    pagesCount: number;
    itemsCount: number;
  };
  isReportsExternalServicesLoading: boolean;
  reportsComparison: IComparisonReport[] | null;
  isReportsComparisonLoading: boolean;
  reportsTopLocations: { [key: string]: string }[];
  isReportsTopLocationsLoading: boolean;
}

export interface IPostingDetailsSamples {
  tabName: string;
  codeSample: string;
}

export interface ICreditUnion {
  id: number | string;
  name: string;
}

export interface IAffiliateChannelPostingDetails {
  id: string;
  name: string;
  requestFormat: string;
  channelId: string;
  password: string;
  responseFormat: string;
  responseEncoding: string;
  requestService: string;
  postSpecificationUrl: string;
  techContact: string;
  postSamples: IPostingDetailsSamples[];
  responseSamples: IPostingDetailsSamples[];
}

export interface IAffiliateState {
  affiliates: IAffiliateProps[] | null;
  simplifiedAffiliates: ISimplifiedList[] | null;
  affiliatesCount: string | null;
  currentAffiliateById: IAffiliateProps | null;
  received: { [key: string]: string } | null;
  affiliateChannels?: IAffiliateChannelProps[] | null;
  simplifiedAffiliateChannels?: ISimplifiedList[] | null;
  affiliateChannelsCount: string | null;
  channel: IAffiliateChannelProps | null;
  currentAffiliateByChannelId: IAffiliateChannelProps | null;
  creditUnions: ICreditUnion[];
  creditUnionsLoading: boolean;
  affiliateChannelPostingDetails: IAffiliateChannelPostingDetails | null;
  isAffiliateChannelsLoading: boolean;
  isAffiliatesLoading: boolean;
  isUpdateAffiliateStatusLoading: boolean;
  isCreateAffiliateLoading: boolean;
  isDeleteAffiliateLoading: boolean;
  isUpdateAffiliateChannelLoading: boolean;
  isCreateAffiliateChannelLoading: boolean;
  isUpdateAffiliateChannelStatusLoading: boolean;
  isDeleteAffiliateChannelLoading: boolean;
  isAffiliateChannelPostingDetailsLoading: boolean;
  isAffiliateChannelHistoryLoading: boolean;
  error: Error | null;
  isLoading: boolean;
  successfully: boolean;
  successfullyCreated: boolean;
  dataFormats: IDataFormat[] | null;
  createdAffiliateChannel: IAffiliateChannelProps | null;
  externalServiceById: IExternalServiceDetails | null;
  affiliateChannelHistory: IHistory[] | null;
  campaignsAffiliateChannelsCount: number;
  affiliateChannelHistoryCount: number;
  affiliateFilters: ISourcePageFilters | null;
  affiliateChannelFilters: ISourcePageFilters | null;
  affiliateChannelDetailsTabs: ChannelTabs;
}

export interface IListsState {
  error: Error | null;
  lists: IListsProps[];
  states: IStatesProps[] | null;
  isLoading: boolean;
  isCreateListValuesLoading: boolean;
  isEditListValueLoading: boolean;
  isSelectedListsLoading: boolean;
  uploadedLists: IUploadListData[] | null;
  selectedLists: ISelectedList[] | null;
}

export interface IExternalServiceState {
  error: Error | null;
  isLoading: false;
  isExternalServiceDeleteLoading: boolean;
  isExternalServiceUpdateLoading: boolean;
  isFieldByDataTypeExternalServicesLoading: boolean;
  isExternalServiceMatchingCampaignFieldsLoading: boolean;
  isExternalServiceDetailsLoading: boolean;
  isExternalServicesCampaignFieldsAutoMatchLoading: boolean;
  isServiceFieldByDataTypeExternalServicesLoading: boolean;
  isExternalServicesCreated: boolean;
  externalServiceCampaignFieldsMatchingValues: IExternalServiceCampaignFieldsMatchingValue[] | null;
  externalServiceCampaignFieldsAutoMatchValues: IExternalServiceCampaignFieldsAutoMatchValue[] | null;
  externalServiceDetails: IExternalServiceDetails | null;
  externalService: IExternalService | null;
  externalServiceCount: string;
  externalServiceList: IExternalService[] | null;
  simplifiedExternalServices: ISelectOption[] | null;
  externalServiceFields: IExternalServiceField[] | null;
  externalServiceResponseFields: IExternalServiceField[] | null;
}

export interface IReportsState {
  error: Error | null;
  comparisonReports: IAffiliatesComparisonReportsTotal | null;
  isReportsLoading: boolean;
  isComparisonReportsLoading: boolean;
  affiliateReports: {
    data: IAffiliatesReports[];
    pagesCount: number;
    itemsCount: number;
  };
  isDownloadLeadListReportLoading: boolean;
}

export interface ISystemFieldState {
  error: Error | null;
  isLoading: boolean;
  systemFieldsList: string[] | null;
}

export interface ITenantState {
  error: Error | null;
  isLoading: boolean;
  logo: string;
}

export interface IUploadListData {
  id: number;
  value: string;
  bWListId: number;
  tenantId: number;
  bWList: {
    id: number;
    name: string;
    listType: number;
    condition: number;
    tenantId: number;
    bwListValues: [];
  };
}

export interface ISelectedList {
  id: string;
  value: string;
  bwListId: string;
  tenantId: number;
  bwList: string | null;
}

export interface ILead {
  address: string;
  affiliateChannelId: number;
  affiliateChannelName: string;
  affiliateId: number;
  affiliateName: string;
  campaignId: number;
  campaignName: string;
  city: string;
  createdUtc: string;
  email: string;
  firstname: string;
  id: number;
  ip: string;
  lastname: string;
  processingTime: number;
  ssn: string | number | null;
  state: string;
  status: number;
  zip: string | number;
  loanAmount: number | null;
  approvedLoanAmount: number | null;
  lastUpdatedDate: string;
}

export interface IExternalServiceCampaignFieldsMatchingValue {
  templateField: string;
  parentField: string;
  campaignId: string;
  campaignFieldId: string;
  externalServiceFieldId: string;
  fixedValue: string | null;
  fieldMatching: [];
  id: string;
}

export interface IExternalServiceCampaignFieldsAutoMatchValue {
  campaign: string | null;
  campaignField: ICampaignField | null;
  campaignFieldId: string;
  campaignId: string;
  externalService: string | null;
  externalServiceCampaignField: IExternalServiceField;
  externalServiceCampaignFieldMatchings: [];
  externalServiceFieldId: string;
  externalServiceId: string;
  fixedValue: string | null;
  id: string;
  tenantId: number;
}

export interface IExternalServiceDetails {
  name: string;
  timeout: number;
  status: number;
  dataFormat: number;
  responseFormat: number;
  dataTemplate: string;
  responseTemplate: string;
  costPerRequest: number;
  postingUrl: string;
  postingHeaders: string;
  isDeleted: boolean;
  fields: IExternalServiceField[];
  responseFields: IExternalServiceResponseField[];
  id: string;
}

export interface IStatesProps {
  count: number;
  id: number;
  name: string;
}

export interface IAffiliateChannelProps {
  id: string | number;
  campaignId: string | number;
  campaignName: string;
  affiliateId: string | number;
  affiliateName: string;
  name: string;
  status: string | number;
  affiliateChannelKey: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
  dataFormat: number;
  overall: boolean;
  cap: number;
  timeout: string;
  notes: string;
}

export interface IAffiliatesReports {
  acceptedLeads: number;
  acceptedLeadsRate: string;
  affiliate: string;
  affiliateChannel: string;
  affiliateChannelId: number;
  campaign: string;
  receivedLeads: number;
  rejectedLeads: number;
  rejectedLeadsRate: string;
  reports: any[];
}

export interface IAffiliatesReportsReqBody {
  affiliateChannels: (number | string)[];
  affiliates: (number | string)[];
  campaigns: (number | string)[];
  startDate: string | Date;
  endDate: string | Date;
  pageIndex: number;
  pageSize: number;
  sortOrder?: {
    key: string;
    value: boolean;
  };
}

export interface IAffiliatesComparisonReportsReqBody {
  affiliateChannels: (number | string)[];
  affiliates: (number | string)[];
  campaigns: number[];
  reportDate: string | Date;
  firstComparisonDate: string | Date;
  secondComparisonDate: string | Date;
  pageIndex: number;
  pageSize: number;
}

export interface IAffiliatesComparisonReports {
  acceptedLeads: number;
  affiliateChannel: string;
  affiliateChannelId: number;
  firstComparisonAcceptedLeads: number;
  firstComparisonAcceptedLeadsRate: string;
  firstComparisonReceivedLeads: number;
  firstComparisonReceivedLeadsRate: string;
  firstComparisonRejectedLeads: number;
  firstComparisonRejectedLeadsRate: string;
  firstComparisonResultAcceptedLeads: number;
  firstComparisonResultReceivedLeads: number;
  firstComparisonResultRejectedLeads: number;
  receivedLeads: number;
  rejectedLeads: number;
  secondComparisonAcceptedLeads: number;
  secondComparisonAcceptedLeadsRate: string;
  secondComparisonReceivedLeads: number;
  secondComparisonReceivedLeadsRate: string;
  secondComparisonRejectedLeads: number;
  secondComparisonRejectedLeadsRate: string;
  secondComparisonResultAcceptedLeads: number;
  secondComparisonResultReceivedLeads: number;
  secondComparisonResultRejectedLeads: number;
}

export interface IAffiliatesComparisonReportsTotal {
  reports: IAffiliatesComparisonReports[];
  totalAcceptedLeads: number;
  totalFirstComparisonAcceptedLeads: number;
  totalFirstComparisonAcceptedLeadsRate: string;
  totalFirstComparisonReceivedLeads: number;
  totalFirstComparisonReceivedLeadsRate: string;
  totalFirstComparisonRejectedLeads: number;
  totalFirstComparisonRejectedLeadsRate: string;
  totalFirstComparisonResultAcceptedLeads: number;
  totalFirstComparisonResultReceivedLeads: number;
  totalFirstComparisonResultRejectedLeads: number;
  totalReceivedLeads: number;
  totalRejectedLeads: number;
  totalSecondComparisonAcceptedLeads: number;
  totalSecondComparisonAcceptedLeadsRate: string;
  totalSecondComparisonReceivedLeads: number;
  totalSecondComparisonReceivedLeadsRate: string;
  totalSecondComparisonRejectedLeads: number;
  totalSecondComparisonRejectedLeadsRate: string;
  totalSecondComparisonResultAcceptedLeads: number;
  totalSecondComparisonResultReceivedLeads: number;
  totalSecondComparisonResultRejectedLeads: number;
}

export interface IAffiliateProps {
  id: string;
  name: string;
  allChannels: number;
  activeChannels: number;
  manager: string;
  registrationDate: string;
  registrationIpAddress: string;
  status: number;
  icon: string;
  createdDate: string;
  createdById: number;
  createdByName: string;
  updatedDate: string;
  updatedById: number;
  updatedByName: string;
  countryId: string;
  city: string;
  managers: string;
  stateProvinceId: string;
  webhookToken?: string;
  statusWebhookUrl: string;
  offersWebhookUrl: string;
  stipulationsWebhookUrl: string;
  secretKey?: string;
  addressLine1?: string;
  addressLine2?: string;
  companyEmail?: string;
  companyPhone?: string;
  zipPostalCode?: string;
}

export interface ISearchModel {
  pageSize: number;
  pageNumber: number;
  sortOrder: {
    key: string;
    value: boolean;
  };
}

export interface ILeadsReqBody {
  dateFrom: Date | string;
  dateTo: Date | string;
  status: number;
  filterBorrowerName: string;
  filterAffiliateIds: string[];
  filterAffiliateChannelIds: string[];
  filterCampaignIds: string[];
  filterLeadIds: string[];
  filterMerchantNames: string[];
  filterEmail: string[];
  filterTags: string[];
  searchModel: ISearchModel;
}

export interface ICreateExternalServiceReqBody {
  id: string;
  name: string;
  timeout: number;
  status: number;
  dataFormat: number;
  responseFormat: number;
  dataTemplate: string;
  responseTemplate: string;
  costPerRequest: number;
  postingUrl: string;
  postingHeaders: string;
  isDeleted: boolean;
  fields: IExternalServiceField[];
  responseFields: IExternalServiceResponseField[];
}

export interface IValidationTreeActionsReqBody {
  id: string;
  affiliateChannelId: string;
  actionType: ValidationTreeActionType;
  parentId?: number;
  orderNum: number;
  logicalOperator: number;
  affiliateChannelTreeId: number;
  actionSettings: [
    {
      Name: string;
      Value: string;
    },
  ];
  scheduleItems: [
    {
      daysOfWeek: number[];
      fromTime: {
        ticks: number;
      };
      toTime: {
        ticks: number;
      };
    },
  ];
  children: IValidationTreeActionsReqBody[];
  trees: number[];
  actionSettingsXml: string;
}

export interface IValidationTree {
  createdById: number;
  createdByName: string;
  createdDate: string;
  id: string;
  name: string;
  selected: boolean;
  updatedById: number;
  updatedByName: string;
  updatedDate: string;
}

export interface IValidationTreeResponse {
  data: IValidationTree[];
  pagesCount: number;
  itemsCount: number;
}

export interface IComparisonReport {
  date: string;
  receivedLeads: number;
  receivedLeadsComparison: number;
  acceptedLeads: number;
  acceptedLeadsComparison: number;
  rejectedLeads: number;
  rejectedLeadsComparison: number;
}

export interface ICreateAffiliateReqTemplate {
  // @TODO: check this type and correct
  addressLine1: string;
  addressLine2: string;
  city: string;
  companyEmail: string;
  companyPhone: string;
  countryCode: string;
  countryId: string;
  name: string;
  stateProvinceId: number;
  zipPostalCode: string;
}

export interface ICreateAffiliateChannelReqTemplate {
  affiliateChannelActions: [];
  affiliateChannelKey: string;
  affiliateChannelPassword: string;
  affiliateId: string;
  campaignId: string;
  dataFormat: number;
  name: string;
  notes: string;
  status: number;
}

export interface IErrorResponseStatus {
  code: string;
  message: string;
}

export interface IAxiosError {
  error: AxiosError<{ status: IErrorResponseStatus }> | null;
}

export interface IDataFormat {
  count: number;
  id: string;
  name: string;
}

export interface IManualReviewReasonsList {
  key: string;
  value: 'IncomeVerification' | 'PropertyTitleChecks' | 'IdentityVerification';
}

export interface ValidationTreeItem {
  id: string;
  name: string;
  creditUnion: number;
  channelTreeType: number;
}

export interface IValidationTreeState {
  validationTrees: null;
  error: Error | null;
  conditions: {
    Id: string;
    Name: string;
  } | null;
  listConditions: {
    Id: string;
    Name: string;
  } | null;
  validationTree: ValidationTreeItem;
  singleValidationTree: IValidationTreeModel[];
  isValidationTreesByIdLoading: boolean;
  validationTreesById: IValidationTreeResponse;
  validationTreeActions: null;
  validationTreeNameSuccess: boolean;
  validationTreeLoading: boolean;
  validationTreeSuccessfully: boolean;
  validationTreeUpdateLoading: boolean;
  isValidationTreeDeleteLoading: boolean;
  manualReviewReasonsList: IManualReviewReasonsList[];
  manualReviewReasonsListLoading: boolean;
  experianFields: ISimplifiedList[];
  validationTreeTypes: IValidationTreeTypes[] | null;
  validationTreeTypesLoading: boolean;
  downloadJsonIsLoading: boolean;
  uploadJsonIsLoading: boolean;
}

export interface IValidationTreeTypes {
  id: number;
  name: string;
}

export interface IValidationTreeFields {
  affiliateChannelId: number;
  actionType: number;
  parentId: number;
  orderNum: number;
  description: string;
  aanDescription: string;
  logicalOperator: number;
  affiliateChannelTreeId: number;
  actionSettings: { name: string; value: string | number }[];
  scheduleItems: {
    daysOfWeek: [];
    fromTime: string;
    toTime: string;
  }[];
  children: [];
  trees: number[];
  id: number;
}

export interface DefaultRootStateProps {
  snackbar: ISnackbarProps;
  validationTrees: IValidationTreeState;
  campaign: ICampaignProps;
  countries: ICountriesState;
  lists: IListsState;
  affiliates: IAffiliateState;
  tableShow: ICampaignProps[] | null;
  dashboard: IDashboardState;
  reports: IReportsState;
  systemFields: ISystemFieldState;
  tenant: ITenantState;

  // We don't use this state
  // user: {
  //   user: any | null;
  //   error: Error | null;
  // };
  leads: ILeadsState;
  externalServices: IExternalServiceState;
}

export interface IListIntegration {
  bwListId?: string;
  id: string;
  value: string;
  condition?: number | null;
  listType?: number | null;
  type?: number;
}

export interface IExternalService {
  createdBy: string;
  createdDate: string;
  id: number;
  name: string;
  status: number;
  updatedBy: string;
  updatedDate: string;
}

export interface IExternalServiceField {
  externalService?: string | null;
  externalServiceId: string;
  fixedValue: string | null;
  id: string;
  parentField: string;
  templateField: string;
  validator: number;
  validatorSettings?: { name: string; value: string | number }[];
  validatorSettingsXml?: string;
  dataType?: number;
  tenantId?: number;
  externalServiceCampaignFields?: [];
}

export interface IExternalServiceResponseField {
  externalServiceId: string;
  templateField: string;
  id: string;
  parentField: string;
  validator: number;
  validatorSettings: { name: string; value: string }[] | [];
}

export interface IExternalServiceUpdateReqBody {
  name: string;
  status: number;
  responseFormat: number;
  dataFormat?: number;
  timeout: number;
  postingUrl: string;
  postingHeaders: string;
  costPerRequest: number;
}

export interface ColorProps {
  readonly [key: string]: string;
}

export interface GuardProps {
  children: ReactElement | null;
}

export interface StringColorProps {
  id?: string;
  label?: string;
  color?: string;
  primary?: string;
  secondary?: string;
}

/** ---- Common Functions types ---- */

export type StringBoolFunc = (s: string) => boolean;
export type StringNumFunc = (s: string) => number;
export type NumbColorFunc = (n: number) => StringColorProps | undefined;

// amit

export interface KeyedObject {
  [key: string]: string | number | KeyedObject | any;
}

export interface DateRange {
  startDate?: Date | string;
  endDate?: Date | string;
  label?: string;
}

export enum ValidationTreeActionType {
  'CampaignFields' = 1,
  'ListIntegration' = 2,
  'ExternalServices' = 4,
  'Experian' = 6,
  'Expression' = 7,
}

export enum ValidationTreeActionId {
  'CampaignFieldId' = 1,
  'BWListId' = 2,
  'ResponseFieldId' = 4,
  'ResponseField' = 6,
}

export interface TableHeader {
  title: string;
  align?: 'left' | 'right' | 'center';
  value: string;
  fixedColumn?: boolean;
  disabled?: boolean;
  sortDisabled?: boolean;
  permission?: Permissions;
}

export interface Status {
  color: string;
  bgColor: string;
  title: string;
  border?: string;
}

export interface ILeadJourney {
  leadId: number;
  affiliateChannelActionId: number;
  createdUtc: string;
  actionType: number;
  actionTypeName: string;
  name: string;
  decisionTreeName: string;
  value: string | null;
  templateFieldValue: string | null;
  isSuccess: boolean;
  message: string;
  aanMessage: string;
  childrenAction: ILeadJourney[];
}

export interface ILeadJourneyStep {
  id: number;
  title: string;
  desc: string;
  value: string | null;
  message: string;
  aanMessage: string;
  className: string;
}

export interface ILeadJourneyGroup {
  id: number;
  steps: ILeadJourneyStep[];
  actionType: number;
  className: string;
}

export interface ILeadJourneyRow {
  id: number;
  title: string;
  groups: ILeadJourneyGroup[];
}

export interface ILeadJourneyActionType {
  title: string;
  className: string;
}

export interface IError {
  response: {
    data: {
      error: Record<string, string>;
    };
  };
}

export interface IPlatformSettings {
  isSlackAppInstalled: boolean;
  inAppNotifications: boolean;
  emailNotifications: boolean;
  slackNotifications: boolean;
  email: string;
  slackChannelName: string;
}

export interface IInputTag {
  email: string;
}

export interface ITenantSettings {
  dailyCap: number | null;
  dailyCapEnabled: boolean;
  dailyCapReached: boolean;
  dailyCapReachedDate: null;
  tenantEmailAddress: IInputTag[];
}

export interface IEmailNotificationsSettings {
  description: string;
  id: string;
  isEnabled: boolean;
  title: string;
  type: NotificationType;
}

export interface IEmailNotificationTypes {
  manualReview: IEmailNotificationsSettings[];
  fundingRequest: IEmailNotificationsSettings[];
}

export type IDuplicatedEmailNotifications = Record<string, IEmailNotificationsSettings>;

export interface IEmailNotificationsSendingData {
  notificationInfoId: string;
  isEnabledForEmail: boolean;
}

export interface ISettingsState {
  error: Error | null;
  qrInfo: null | { imageUrl: string; manualKey: string };
  settingsLoading: boolean;
  verificationError: string;
  verificationEnabled: boolean;
  settingsStatus: boolean;
  initializationLoading: boolean;
  platformSettings: IPlatformSettings | null;
  platformSettingsLoading: boolean;
  tenantSettings: ITenantSettings | null;
  tenantSettingsLoading: boolean;
  notificationTypes: IEmailNotificationTypes | null;
  duplicatedNotificationTypes: IDuplicatedEmailNotifications | null;
  notificationTypesLoading: boolean;
}

export enum ModalName {
  NONE = '',
  ConfirmCampaignDeleteModal = 'ConfirmCampaignDeleteModal',
  ConfirmAffiliateDeleteModal = 'ConfirmAffiliateDeleteModal',
  ConfirmAffiliateChannelDeleteModal = 'ConfirmAffiliateChannelDeleteModal',
  ConfirmExternalServiceDeleteModal = 'ConfirmExternalServiceDeleteModal',
  ConfirmAffiliateChannelTreeDeleteModal = 'ConfirmAffiliateChannelTreeDeleteModal',
  ConfirmAffiliateChannelCreateSuccessModal = 'ConfirmAffiliateChannelCreateSuccessModal',
  AffiliateChannelTreeUploadJsonModal = 'AffiliateChannelTreeUploadJsonModal',
  ConfirmAffiliateChannelRuleNameModal = 'ConfirmAffiliateChannelRuleNameModal',
  ConfirmListIntegrationDeleteModal = 'ConfirmListIntegrationDeleteModal',
  ConfirmListIntegrationAddModal = 'ConfirmListIntegrationAddModal',
  ConfirmCreateCampaignModal = 'ConfirmCreateCampaignModal',
  ConfirmCampaignCloneModal = 'ConfirmCampaignCloneModal',
  ConfirmCampaignHistoryModal = 'ConfirmCampaignHistoryModal',
  ConfirmListIntegrationDetailsAddModal = 'ConfirmListIntegrationDetailsAddModal',
  ConfirmAffiliateDetailsSaveChangesModal = 'ConfirmAffiliateDetailsSaveChangesModal',
  ConfirmExternalServiceSuccessModal = 'ConfirmExternalServiceSuccessModal',
  ConfirmAffiliateChannelEditSaveModal = 'ConfirmAffiliateChannelEditSaveModal',
  AddPromotionModal = 'AddPromotionModal',
  InviteNewUserModal = 'InviteNewUserModal',
  EditUserRoleModal = 'EditUserRoleModal',
  ResendInviteUserModal = 'ResendInviteUserModal',
  DeactivateUserModal = 'DeactivateUserModal',
  ResetPasswordModal = 'ResetPasswordModal',
  ResetDailyCapModal = 'ResetDailyCapModal',
  RejectApplicantModal = 'RejectApplicantModal',
  ShareManualReviewModal = 'ShareManualReviewModal',
  TurnOnIntegrationModal = 'TurnOnIntegrationModal',
  PostedToAPIModal = 'PostedToAPIModal',
  DeleteOutboundAPIModal = 'DeleteOutboundAPIModal',
  ScheduleModal = 'ScheduleModal',
  RerunModal = 'RerunModal',
  CapModal = 'CapModal',
  AanModal = 'AanModal',
  DescriptionModal = 'DescriptionModal',
  PauseModal = 'PauseModal',
  FundingRequestRejectModal = 'FundingRequestRejectModal',
  ConfirmAffiliateChannelHistoryModal = 'ConfirmAffiliateChannelHistoryModal',
  TwoFactorAuthenticationModal = 'TwoFactorAuthenticationModal',
  TwoFactorAuthenticationDisableModal = 'TwoFactorAuthenticationDisableModal',
  TwoFactorAuthenticationResultModal = 'TwoFactorAuthenticationResultModal',
  DocumentsUploadFilesModal = 'DocumentsUploadFilesModal',
  XMLModal = 'XMLModal',
  DeleteTagModal = 'DeleteTagModal',
  RevertApplicationModal = 'RevertApplicationModal',
  DeleteUserModal = 'DeleteUserModal',
  DomainRestrictionsModal = 'DomainRestrictionsModal',
  ResendDocumentModal = 'ResendDocumentModal',
  CreateRoleModal = 'CreateRoleModal',
  EditRoleModal = 'EditRoleModal',
  DeleteRoleModal = 'DeleteRoleModal',
  AddNoteModal = 'AddNoteModal',
  DeleteNoteModal = 'DeleteNoteModal',
  ReasonUploadFilesModal = 'ReasonUploadFilesModal',
  SolarPanelEditModal = 'SolarPanelEditModal',
  DeleteFileModal = 'DeleteFileModal',
  CreateReasonModal = 'CreateReasonModal',
  EmailNotificationsModal = 'EmailNotificationsModal',
  CancelApplicationModal = 'CancelApplicationModal',
}

export interface IOpenedModal<P> {
  name: ModalName;
  props: P;
}

export interface IOpenedAlert<P> {
  open: boolean;
  props: P;
}

export interface RedirectionProps {
  path: string;
  params?: string;
  apply: boolean;
}

export interface ViewsProps {
  modals: IOpenedModal<unknown>[];
  toastNotificationPopUp: IOpenedAlert<any>;
  redirection: RedirectionProps;
}
export const getInitialState = (): ViewsProps => ({
  modals: [],
  toastNotificationPopUp: {
    open: false,
    props: {},
  },
  redirection: {
    path: '/',
    params: '',
    apply: false,
  },
});
